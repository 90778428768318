.accordion-button {
    background-color: black !important;
    color: darkgrey !important;
    text-align: center !important;
    display: inline-block !important;
}

.accordion-button:focus {
    box-shadow: none;
    color: darkgrey;
}

.accordion-button:not(.collapsed) {
    color: gray;
}

.accordion-body {
    background-color: black !important;
    color: #e87958;
    text-align: center;
}

.header_th {
    margin-top: 20px;
    color: #b7b7a7;
}

.bard {
    padding-top: 5px;
    color: rgb(158, 178, 177);
}

.sticky-top {
    z-index: revert !important;
}