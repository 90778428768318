
html,
body {
    height: 100%;
    color: rgb(248, 248, 255);

    background-image: url('img/fons_bg-530x180.jpg');

    /* background-color: #1a1a1a;*/

}

a {
    color: rgb(45, 49, 45);
}

#takee {
    color: white;
}

a:hover {
    color: rgb(193, 203, 193);
}

a:active {
    color: rgb(115, 121, 115);
    text-decoration: none;
}

a:visited {
    color: rgb(115, 121, 115);
    text-decoration: none;
}

#magnitola{
    padding-bottom: 15px;
}


.grad {
    height: 200px;
    background: linear-gradient(to top, #E4AF9D 20%, #E4E4D8 50%, #A19887 80%);
}

/*html, body {
    margin: 0px;
    padding:30px 0;
    width:100%;
    height:100%;
    font-family: Arial;
    text-align:center;
}
body{
    background:url(webdoc.png);
}
.container{
    width:500px;
    margin: 0 auto;
}
*/
.wrap > .container {
    padding: 15px 0 0;
    max-width: 700px;

    /*box-shadow: 0 6px 4px -4px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 6px 4px -4px rgba(189, 217, 233, 0.99);*/

}
.text-center{
    padding-top: 0px;
}

.latvia{
    background-image: url('img/radio_latvia.jpg');

    background-position: top; /* Положение фона */
    background-repeat: no-repeat; /* Повторяем фон по горизонтали */
    background-color: #080808;
    background-size: 100%;
    border: 2px solid rgb(158, 178, 177);;
    border-radius: 5px;
}

.content{
    padding-top: 45%;

}
.btn-success {
    width: 100%;
    overflow: hidden;
}

.bard_img{
    vertical-align: middle;
    width: 25%;
    padding-top: 20px;
}


body::-webkit-scrollbar{ width: 0px; /* 1 - вертикальный скроллбар */}

#page::-webkit-scrollbar:horizontal{ height: 22px; /* 1 - горизонтальный скроллбар */}
#page::-webkit-scrollbar-button {background: #008000; /* 2 - кнопка */}
#page::-webkit-scrollbar-track {background: #008000;/* 3 - трек */}
#page::-webkit-scrollbar-track-piece { background: rgb(73, 148, 75); /* 4 – видимая часть трека */ }
#page::-webkit-scrollbar-thumb {background: #35ee2c; border-radius: 10px; -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); /* 5 - ползунок */}

/****** Lines *******/
.line {
    overflow:hidden;
    width:100%;
    /*height: 400px;*/
    border: 2px solid rgb(158, 178, 177);
    border-radius: 5px;
    box-shadow: inset 0 0 6px;
    background: rgb(8, 6, 6);
    /*opacity: 0.8; /* Полупрозрачный фон */
    /*box-shadow:0px 5px 5px 3px rgba(0,0,0,0.3);*/
    display:block;
    /*margin-top:10px;
    border-radius:2px;*/
    position:relative;
    padding-top: 3px;
}

.animator{
    animation: 6s show ease-in-out;
}
.line .line_cover{
    width:100%;
    height:100%;
    position:absolute;
    /*z-index:2;*/
    /*background:url(<?php //\yii\helpers\Url::to('/img/talsvtod.png')?>);*/
}
.line .line_text{
    width:100%;
    height:100%;
    position:absolute;
    z-index:1;
    padding-top: 7px;
}
/****** Line 1 *******/
#l1.line{
    height: 38px;
    background: #2e2e2e;
    margin-top: 10px;
}
#l1.line .line_text{
    font-size: 20px;
    /*font-weight: bold;*/
    /* font-weight: bold; */
    width: 1600px;
    /*width: 900px;*/
    color: rgb(248, 249, 255);
    -webkit-animation: l1_animation 10s linear infinite;
    -moz-animation: l1_animation 10s linear infinite;
}
@-webkit-keyframes l1_animation {
    0%{left:100%;}
    100%{left:-100%;}
}
@-moz-keyframes l1_animation {
    0%{left:100%;}
    100%{left:-100%;}
}
/****** Line 11 *******/
#l11.line{
    height: 50px;
    background: #2e2e2e;
    margin-top: 10px;
}
#l11.line .line_text{
    font-size: 20px;
    /*font-weight: bold;*/
    /* font-weight: bold; */
    width: 1600px;
    /*width: 900px;*/
    color: rgb(248, 249, 255);
    -webkit-animation: l1_animation 8s linear infinite;
    -moz-animation: l1_animation 8s linear infinite;
}
/****** Line 12 *******/
#l12.line{
    height: 50px;
    background: #2e2e2e;
    margin-top: 10px;
}
#l12.line .line_text{
    font-size: 20px;
    /*font-weight: bold;*/
    /* font-weight: bold; */
    width: 1600px;
    /*width: 900px;*/
    color: rgb(248, 249, 255);
    -webkit-animation: l1_animation 12s linear infinite;
    -moz-animation: l1_animation 12s linear infinite;
}



/****** Line 2 *******/
#l2.line{
    height:70px;
    background:rgb(90,60,50);
}
#l2.line .line_text{
    font-size:60px;;
    position:absolute;
    color:#ffb400;
    font-weight:bold;
    -webkit-animation: l2_animation 5s linear infinite;
    -moz-animation: l2_animation 5s linear infinite;
}
@-webkit-keyframes l2_animation {
    0%   { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}
@-moz-keyframes l2_animation {
    0%   { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}
/****** Line 3 *******/
/*
#l3.line{
    height:100%;
    background: rgb(8, 6, 6);
    margin-bottom: 10px;
}
#l3.line .line_text{
    font-size:15px;
    /*position:absolute;
    color: rgb(86, 186, 89);
    padding-top: 0px;
    /*font-weight:bold;
    font-family:"Trebuchet MS", Helvetica, sans-serif;
    -webkit-animation: l3_animation 20s linear infinite;
    -moz-animation: l3_animation 20s linear infinite;
}
*/

.active-button {
    background-color: rgb(255, 131, 96);
    /*background-color: rgb(86, 186, 89);*/
}


.btn{
    width: 100%;
    color: #1c1c1c;
    overflow: hidden;
    margin-top: 10px;
}
.btn:hover {
    color: #fcf8e3;
}

.btn:active{
    color: #fcf8e3;
}

/*регулятор громкости*****/
#Panel{
    position: absolute;
    top: 125px;
    left: 10%;
}
#Container
{
    position: relative;
    background-image: url('img/rheostat.png');
    width: 64px;
    height: 64px;

}

#Indicator
{
    position: absolute;
    background-image: url('img/indicator.png');
    width: 4px;
    height: 4px;
    visibility: hidden;
}
#Text{
    position: absolute;
    top: 20px;
    left: 22px;
    color: rgb(0, 0, 0);
}
#Panel2{
    position: absolute;
    top: 125px;
    left: 85%;
}
#Container2
{
    position: relative;
    background-image: url('img/rheostat.png');
    width: 64px;
    height: 64px;

}

#Indicator2
{
    position: absolute;
    background-image: url('img/indicator.png');
    width: 4px;
    height: 4px;
    visibility: hidden;
}
#Text2{
    position: absolute;
    top: 20px;
    left: 22px;
    color: rgb(0, 0, 0);
}

/************************/

#play_btn{
    position: relative;

}

#play{

    display: none;
}

.on_button {
    width: 10%;
    /* height: 35px;*/
    border-radius: 7px;
    background-color: gray;
    color: #0a0a0a;
    margin: 0;
}

.on_button:hover{
    background-color: #e29279;
}

#play_btn.off_button, #stop_btn.off_button{
    background-color: #de5228;
}

.off_button {
    width: 10%;
    /* height: 35px;*/
    border-radius: 7px;
    color: #0a0a0a;
    margin: 0;
    background-color: lightgray;
}


#l3.line {
    height: 80%;
}

#buttons{
    /*display: none;*/
   /* margin-left: 30%;*/
}
/*
#wellcome img{
    text-align: center;
    background-color: rgb(90, 90, 90);
    max-width: 100%;
    max-height: 100%;
    padding: 15px;
}
*/

@-webkit-keyframes l3_animation {
    0%    {color: rgb(189, 217, 233); }
    20%   {color: rgb(139, 173, 196); }
    40%   {color: rgb(90, 90, 90); }
    60%   {color: rgb(218, 212, 195); }
    80%   {color: rgb(255,255,255); }
    100%  {color: rgb(184, 180, 210); }
}
@-moz-keyframes l3_animation {
    0%    {color: rgb(189, 217, 233); }
    20%   {color: rgb(139, 173, 196); }
    40%   {color: rgb(90, 90, 90); }
    60%   {color: rgb(218, 212, 195); }
    80%   {color: rgb(255,255,255); }
    100%  {color: rgb(184, 180, 210); }
}

@media (min-width: 1200px) {
    .content {
        padding-top: 38%;
    }
    #Panel{
        position: absolute;
        top: 125px;
        left: 11%;
    }
    #Panel2{
        position: absolute;
        top: 125px;
        left: 85%;
    }
}

@media(min-width:700px) and (max-width:800px) {

    #Panel {
        top: 81px;
        left: 10%;
    }

    #Text {
        top: 20px;
        left: 18px;

    }
    #Panel2 {
        top: 81px;
        left: 82%;
    }

    #Text2 {
        top: 20px;
        left: 18px;

    }
}


@media(min-width:400px) and (max-width:700px) {
    .container .content {
        padding-top: 50%;
        text-align: center;
    }

    .latvia {
        width: 100%;
        background-size: 100%;
        /*height: 170px;*/
    }

    /*.container .content{
        padding-top: 60%;
        text-align: center;
    }
    */
    .bard_img {
        width: 30%;
    }


    #l3.line .line_text{
        font-size:13px;

    }

    .bard_img {
        padding-top: 0;
    }

    #Container {
        width: 44px;
        height: 44px;
        background-size: 44px 44px;
        top: 22px;
    }



    #Text {
        top: 13px;
        left: 11px;

    }
    #Container2 {
        width: 44px;
        height: 44px;
        background-size: 44px 44px;
        top: 22px;
    }



    #Text2 {
        top: 13px;
        left: 11px;

    }

}

/*adaptive Panel*/

@media(min-width:400px) and (max-width:500px) {
    .container .content {
        padding-top: 55%;
    }
    #Panel {
        top: 50px;
        left: 10%;
    }
    #Panel2 {
        top: 50px;
        left: 80%;
    }
}

@media(min-width:500px) and (max-width:600px) {
    .container .content {
        padding-top: 55%;
    }
    #Panel {
        top: 55px;
        left: 10%;
    }
    #Panel2 {
        top: 55px;
        left: 82%;
    }
}

@media(min-width:600px) and (max-width:700px) {
    .container .content {
        padding-top: 55%;
    }
    #Panel {
        top: 65px;
        left: 12%;
    }
    #Panel2 {
        top: 65px;
        left: 84%;
    }
}

@media(min-width:800px) and (max-width:900px) {
    .container .content {
        padding-top: 55%;
    }
    #Panel {
        top: 85px;
        left: 10%;
    }
    #Panel2 {
        top: 85px;
        left: 82%;
    }
}

@media(min-width:900px) and (max-width:978px) {
    .container .content {
        padding-top: 55%;
    }
    #Panel {
        top: 85px;
        left: 10%;
    }
    #Panel2 {
        top: 85px;
        left: 82%;
    }
}

@media(min-width:978px) and (max-width:1200px) {
    .container .content {
        padding-top: 55%;
    }
    #Panel {
        top: 105px;
        left: 11%;
    }
    #Panel2 {
        top: 105px;
        left: 84%;
    }
}


@media(min-width:150px) and (max-width:500px) {
    .container .content {
        padding-top: 62%;
    }

    .on_button {
        width: 14.5%;
    }
    .off_button {
        width: 14.5%;
    }
    #buttons{
        display: block;
       /* margin-left: 7%;*/
    }
    #l1.line .line_text {
        font-size: 15px;
    }
    p {
        margin: 0 0 0px;
    }
    h4 {
        font-size: 13px;
    }


}



@media(min-width:150px) and (max-width:400px){

    #play{
        display: block;
    }

    #buttons{
        display: block;
    }

    #cats, #acc {
        display: none;
    }
    h4 {
        font-size: 13px;
    }

    .container .content {
        padding-top: 50%;
        text-align: center;
    }

    .latvia {
        width: 100%;
        background-size: 100%;
        /*height: 170px;*/
    }

    /*.container .content{
        padding-top: 60%;
        text-align: center;
    }
    */
    .bard_img {
        width: 30%;
    }

    #l3.line {
        /*height: 43px;*/
    }
    #l3.line .line_text{
        font-size:13px;

    }

    .bard_img {
        padding-top: 0;
    }

    #Container {
        width: 44px;
        height: 44px;
        background-size: 44px 44px;
        top: 8px;
    }

    #Panel {
        top: 50px;
        left: 9%;
    }

    #Text {
        top: 13px;
        left: 11px;

    }
    #Container2 {
        width: 44px;
        height: 44px;
        background-size: 44px 44px;
        top: 22px;
    }

    #Panel2 {
        top: 50px;
        left: 80%;
    }

    #Text2 {
        top: 13px;
        left: 11px;

    }

}

.glyphicon {
    color: #0a0a0a;
}

.fa {
    color: #0a0a0a;
}

@font-face{font-family:'Glyphicons Halflings';
    src:url('https://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.eot');
    src:url('https://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('https://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('https://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
    url('https://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');}
.glyphicon{position:relative;top:1px;display:inline-block;font-family:'Glyphicons Halflings';font-style:normal;font-weight:normal;line-height:1;-webkit-font-smoothing:antialiased;}
.glyphicon-asterisk:before{content:"\2a";}
.glyphicon-plus:before{content:"\2b";}
.glyphicon-euro:before{content:"\20ac";}
.glyphicon-minus:before{content:"\2212";}
.glyphicon-cloud:before{content:"\2601";}
.glyphicon-envelope:before{content:"\2709";}
.glyphicon-pencil:before{content:"\270f";}
.glyphicon-glass:before{content:"\e001";}
.glyphicon-music:before{content:"\e002";}
.glyphicon-search:before{content:"\e003";}
.glyphicon-heart:before{content:"\e005";}
.glyphicon-star:before{content:"\e006";}
.glyphicon-star-empty:before{content:"\e007";}
.glyphicon-user:before{content:"\e008";}
.glyphicon-film:before{content:"\e009";}
.glyphicon-th-large:before{content:"\e010";}
.glyphicon-th:before{content:"\e011";}
.glyphicon-th-list:before{content:"\e012";}
.glyphicon-ok:before{content:"\e013";}
.glyphicon-remove:before{content:"\e014";}
.glyphicon-zoom-in:before{content:"\e015";}
.glyphicon-zoom-out:before{content:"\e016";}
.glyphicon-off:before{content:"\e017";}
.glyphicon-signal:before{content:"\e018";}
.glyphicon-cog:before{content:"\e019";}
.glyphicon-trash:before{content:"\e020";}
.glyphicon-home:before{content:"\e021";}
.glyphicon-file:before{content:"\e022";}
.glyphicon-time:before{content:"\e023";}
.glyphicon-road:before{content:"\e024";}
.glyphicon-download-alt:before{content:"\e025";}
.glyphicon-download:before{content:"\e026";}
.glyphicon-upload:before{content:"\e027";}
.glyphicon-inbox:before{content:"\e028";}
.glyphicon-play-circle:before{content:"\e029";}
.glyphicon-repeat:before{content:"\e030";}
.glyphicon-refresh:before{content:"\e031";}
.glyphicon-list-alt:before{content:"\e032";}
.glyphicon-flag:before{content:"\e034";}
.glyphicon-headphones:before{content:"\e035";}
.glyphicon-volume-off:before{content:"\e036";}
.glyphicon-volume-down:before{content:"\e037";}
.glyphicon-volume-up:before{content:"\e038";}
.glyphicon-qrcode:before{content:"\e039";}
.glyphicon-barcode:before{content:"\e040";}
.glyphicon-tag:before{content:"\e041";}
.glyphicon-tags:before{content:"\e042";}
.glyphicon-book:before{content:"\e043";}
.glyphicon-print:before{content:"\e045";}
.glyphicon-font:before{content:"\e047";}
.glyphicon-bold:before{content:"\e048";}
.glyphicon-italic:before{content:"\e049";}
.glyphicon-text-height:before{content:"\e050";}
.glyphicon-text-width:before{content:"\e051";}
.glyphicon-align-left:before{content:"\e052";}
.glyphicon-align-center:before{content:"\e053";}
.glyphicon-align-right:before{content:"\e054";}
.glyphicon-align-justify:before{content:"\e055";}
.glyphicon-list:before{content:"\e056";}
.glyphicon-indent-left:before{content:"\e057";}
.glyphicon-indent-right:before{content:"\e058";}
.glyphicon-facetime-video:before{content:"\e059";}
.glyphicon-picture:before{content:"\e060";}
.glyphicon-map-marker:before{content:"\e062";}
.glyphicon-adjust:before{content:"\e063";}
.glyphicon-tint:before{content:"\e064";}
.glyphicon-edit:before{content:"\e065";}
.glyphicon-share:before{content:"\e066";}
.glyphicon-check:before{content:"\e067";}
.glyphicon-move:before{content:"\e068";}
.glyphicon-step-backward:before{content:"\e069";}
.glyphicon-fast-backward:before{content:"\e070";}
.glyphicon-backward:before{content:"\e071";}
.glyphicon-play:before{content:"\e072";}
.glyphicon-pause:before{content:"\e073";}
.glyphicon-stop:before{content:"\e074";}
.glyphicon-forward:before{content:"\e075";}
.glyphicon-fast-forward:before{content:"\e076";}
.glyphicon-step-forward:before{content:"\e077";}
.glyphicon-eject:before{content:"\e078";}
.glyphicon-chevron-left:before{content:"\e079";}
.glyphicon-chevron-right:before{content:"\e080";}
.glyphicon-plus-sign:before{content:"\e081";}
.glyphicon-minus-sign:before{content:"\e082";}
.glyphicon-remove-sign:before{content:"\e083";}
.glyphicon-ok-sign:before{content:"\e084";}
.glyphicon-question-sign:before{content:"\e085";}
.glyphicon-info-sign:before{content:"\e086";}
.glyphicon-screenshot:before{content:"\e087";}
.glyphicon-remove-circle:before{content:"\e088";}
.glyphicon-ok-circle:before{content:"\e089";}
.glyphicon-ban-circle:before{content:"\e090";}
.glyphicon-arrow-left:before{content:"\e091";}
.glyphicon-arrow-right:before{content:"\e092";}
.glyphicon-arrow-up:before{content:"\e093";}
.glyphicon-arrow-down:before{content:"\e094";}
.glyphicon-share-alt:before{content:"\e095";}
.glyphicon-resize-full:before{content:"\e096";}
.glyphicon-resize-small:before{content:"\e097";}
.glyphicon-exclamation-sign:before{content:"\e101";}
.glyphicon-gift:before{content:"\e102";}
.glyphicon-leaf:before{content:"\e103";}
.glyphicon-eye-open:before{content:"\e105";}
.glyphicon-eye-close:before{content:"\e106";}
.glyphicon-warning-sign:before{content:"\e107";}
.glyphicon-plane:before{content:"\e108";}
.glyphicon-random:before{content:"\e110";}
.glyphicon-comment:before{content:"\e111";}
.glyphicon-magnet:before{content:"\e112";}
.glyphicon-chevron-up:before{content:"\e113";}
.glyphicon-chevron-down:before{content:"\e114";}
.glyphicon-retweet:before{content:"\e115";}
.glyphicon-shopping-cart:before{content:"\e116";}
.glyphicon-folder-close:before{content:"\e117";}
.glyphicon-folder-open:before{content:"\e118";}
.glyphicon-resize-vertical:before{content:"\e119";}
.glyphicon-resize-horizontal:before{content:"\e120";}
.glyphicon-hdd:before{content:"\e121";}
.glyphicon-bullhorn:before{content:"\e122";}
.glyphicon-certificate:before{content:"\e124";}
.glyphicon-thumbs-up:before{content:"\e125";}
.glyphicon-thumbs-down:before{content:"\e126";}
.glyphicon-hand-right:before{content:"\e127";}
.glyphicon-hand-left:before{content:"\e128";}
.glyphicon-hand-up:before{content:"\e129";}
.glyphicon-hand-down:before{content:"\e130";}
.glyphicon-circle-arrow-right:before{content:"\e131";}
.glyphicon-circle-arrow-left:before{content:"\e132";}
.glyphicon-circle-arrow-up:before{content:"\e133";}
.glyphicon-circle-arrow-down:before{content:"\e134";}
.glyphicon-globe:before{content:"\e135";}
.glyphicon-tasks:before{content:"\e137";}
.glyphicon-filter:before{content:"\e138";}
.glyphicon-fullscreen:before{content:"\e140";}
.glyphicon-dashboard:before{content:"\e141";}
.glyphicon-heart-empty:before{content:"\e143";}
.glyphicon-link:before{content:"\e144";}
.glyphicon-phone:before{content:"\e145";}
.glyphicon-usd:before{content:"\e148";}
.glyphicon-gbp:before{content:"\e149";}
.glyphicon-sort:before{content:"\e150";}
.glyphicon-sort-by-alphabet:before{content:"\e151";}
.glyphicon-sort-by-alphabet-alt:before{content:"\e152";}
.glyphicon-sort-by-order:before{content:"\e153";}
.glyphicon-sort-by-order-alt:before{content:"\e154";}
.glyphicon-sort-by-attributes:before{content:"\e155";}
.glyphicon-sort-by-attributes-alt:before{content:"\e156";}
.glyphicon-unchecked:before{content:"\e157";}
.glyphicon-expand:before{content:"\e158";}
.glyphicon-collapse-down:before{content:"\e159";}
.glyphicon-collapse-up:before{content:"\e160";}
.glyphicon-log-in:before{content:"\e161";}
.glyphicon-flash:before{content:"\e162";}
.glyphicon-log-out:before{content:"\e163";}
.glyphicon-new-window:before{content:"\e164";}
.glyphicon-record:before{content:"\e165";}
.glyphicon-save:before{content:"\e166";}
.glyphicon-open:before{content:"\e167";}
.glyphicon-saved:before{content:"\e168";}
.glyphicon-import:before{content:"\e169";}
.glyphicon-export:before{content:"\e170";}
.glyphicon-send:before{content:"\e171";}
.glyphicon-floppy-disk:before{content:"\e172";}
.glyphicon-floppy-saved:before{content:"\e173";}
.glyphicon-floppy-remove:before{content:"\e174";}
.glyphicon-floppy-save:before{content:"\e175";}
.glyphicon-floppy-open:before{content:"\e176";}
.glyphicon-credit-card:before{content:"\e177";}
.glyphicon-transfer:before{content:"\e178";}
.glyphicon-cutlery:before{content:"\e179";}
.glyphicon-header:before{content:"\e180";}
.glyphicon-compressed:before{content:"\e181";}
.glyphicon-earphone:before{content:"\e182";}
.glyphicon-phone-alt:before{content:"\e183";}
.glyphicon-tower:before{content:"\e184";}
.glyphicon-stats:before{content:"\e185";}
.glyphicon-sd-video:before{content:"\e186";}
.glyphicon-hd-video:before{content:"\e187";}
.glyphicon-subtitles:before{content:"\e188";}
.glyphicon-sound-stereo:before{content:"\e189";}
.glyphicon-sound-dolby:before{content:"\e190";}
.glyphicon-sound-5-1:before{content:"\e191";}
.glyphicon-sound-6-1:before{content:"\e192";}
.glyphicon-sound-7-1:before{content:"\e193";}
.glyphicon-copyright-mark:before{content:"\e194";}
.glyphicon-registration-mark:before{content:"\e195";}
.glyphicon-cloud-download:before{content:"\e197";}
.glyphicon-cloud-upload:before{content:"\e198";}
.glyphicon-tree-conifer:before{content:"\e199";}
.glyphicon-tree-deciduous:before{content:"\e200";}
.glyphicon-briefcase:before{content:"\1f4bc";}
.glyphicon-calendar:before{content:"\1f4c5";}
.glyphicon-pushpin:before{content:"\1f4cc";}
.glyphicon-paperclip:before{content:"\1f4ce";}
.glyphicon-camera:before{content:"\1f4f7";}
.glyphicon-lock:before{content:"\1f512";}
.glyphicon-bell:before{content:"\1f514";}
.glyphicon-bookmark:before{content:"\1f516";}
.glyphicon-fire:before{content:"\1f525";}
.glyphicon-wrench:before{content:"\1f527";}

@-webkit-keyframes scroll {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0)
    }
}

@-moz-keyframes scroll {
    0% {
        -moz-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    100% {
        -moz-transform: translate(-100%, 0);
        transform: translate(-100%, 0)
    }
}

@keyframes scroll {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0)
    }
}

.marquee {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.marquee span {
    display: inline-block;
    padding-left: 100%;
    font-size: larger;
    -webkit-animation: scroll 12s infinite linear;
    -moz-animation: scroll 12s infinite linear;
    animation: scroll 12s infinite linear;
}

span {
    color: white;
}

.pic_bard {
    height: 80%;
    border-radius: 5px;
    max-height: 80%;
    max-width: 100%;
    background-color: rgba(84, 84, 84, 0.2);
    opacity: 0.8;
    margin-left: 15px;
}
/*
.img-wrap {
    width: 200px;
    height: 150px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0;
}

div > .pic {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
}
*/
.css-adaptive {
    border-radius: 10px;
    max-width: 100%;
    height: 100%;
    padding: 5px;
    animation: 6s show ease-in-out;
    opacity: 0.8;
}

@keyframes show {
    from { opacity: 0; }
    to { opacity: 0.8; }
}

.bottom-d {
    display: grid;
    align-items: end;
}

#rockncontroll{
    color: slategray;
}

